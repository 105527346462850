import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import Button from 'components/Button'
import { useBusinessFormContext, Actions } from 'components/Form/store/business'
import { useUserFormContext } from 'components/Form/store/user'
import { Stack, Typography } from '@mui/material'
import {
  ShopStore,
  Clothes,
  Chevron,
  DrinkGlass,
  ShoppingCart,
  Health,
  Heart,
  Hotel,
  Plane,
  Book,
  Computer,
  Attache,
  DeliveryTruck,
  Entertainment,
  SocialImpact,
  Gas,
  Car,
  House,
} from 'components/Icons'
import CardGroup from 'components/CardGroup'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import DATA from 'constants/pages/businessActivity.json'
import theme from 'theme'

import { ROUTE_BUSINESS_INFO } from 'constants/routes'
import SubmitError from 'components/SubmitError'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const ICONS = [
  ShopStore,
  DrinkGlass,
  ShoppingCart,
  Clothes,
  Health,
  Heart,
  Hotel,
  Plane,
  Book,
  Computer,
  Attache,
  DeliveryTruck,
  Entertainment,
  SocialImpact,
  Gas,
  Car,
  House,
]

const BusinessActivityPage = () => {
  const navigate = useNavigate()
  const { device } = useGlobalContext()
  const businessNameId = DATA.FORMIKS_ID.BUSINESS_NAME_ID
  const { businessState: businessInfo, businessDispatch } =
    useBusinessFormContext()
  const [showMore, setShowMore] = useState(false)
  const { limit } = DATA
  const { userState } = useUserFormContext()
  const [openSnackbar, setOpenSnackbar] = useState(false)

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const activitiesWithIcons = DATA.activity_values.map((item, index) => ({
    ...item,
    icon: ICONS[index],
  }))

  const BUSINESS_INFO_VALIDATION = yup.object().shape({
    [businessNameId]: yup.string().test('test-not-empty', '', (value) => {
      const returnValue = !!value

      if (!returnValue) {
        setOpenSnackbar(true)
        segment.track(DATA.SEGMENT.EVENTS.INVALID_BUSINESS_ACTIVITY)
      }

      return returnValue
    }),
  })

  const formik = useFormik({
    initialValues: {
      [businessNameId]: '',
    },
    onSubmit: (values) => {
      businessDispatch({
        business_info: {
          ...businessInfo?.business_info,
          businessId: values[businessNameId],
        },
        type: Actions.UPDATE_BUSINESS_INFO,
      })

      const activityName = activitiesWithIcons.find(
        (item) => item.value === values[businessNameId]
      )?.label

      const segmentData = {
        business_activity: activityName,
        business_industry_id: values[businessNameId],
      }

      segment.track(DATA.SEGMENT.EVENTS.ADD_BUSINESS_ACTIVITY, segmentData)
      segment.identify(userState.mid, segmentData)

      navigate(ROUTE_BUSINESS_INFO)
    },
    validationSchema: BUSINESS_INFO_VALIDATION,
  })

  const handleChange = (value) => {
    formik.setFieldValue(businessNameId, value.value)
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      ActivitySelected: value.value,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.selected,
    })
  }

  const handleShowMore = () => {
    setShowMore(true)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.see_more,
    })

    segment.track(DATA.SEGMENT.EVENTS.SHOW_MORE_BUSINESS_ACTIVITIES)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        onContinue={formik.handleSubmit}
      >
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <CardGroup
              id={businessNameId}
              items={activitiesWithIcons}
              maxItems={showMore ? DATA.activity_values.length : limit}
              onChange={handleChange}
              value={formik.values[businessNameId]}
            />

            {!showMore && (
              <Button onClick={handleShowMore}>
                <Chevron color={theme.palette.primary.main} rotate={90} />
                <Typography
                  color="primary.main"
                  fontWeight={theme.fontWeight.bold}
                  variant="body1"
                >
                  {DATA.showMore}
                </Typography>{' '}
              </Button>
            )}
          </Stack>
        </form>
      </StepWrapper>
      <SubmitError
        msg={DATA.errorLabel}
        onClose={handleCloseSnackbar}
        open={openSnackbar}
      />
    </Layout>
  )
}

export default BusinessActivityPage
