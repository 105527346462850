import { getToken } from './tokenHelpers'
import { ROUTE_ACCOUNT_CREATION } from 'constants/routes'

const navigateIfNotLoggedIn = (navigate, route = ROUTE_ACCOUNT_CREATION) => {
  if (typeof navigate !== 'function' || !navigate) {
    throw new Error(
      'redirectIfNotLoggedIn: navigate param must be a function returned by the useNavigate hook'
    )
  }

  if (!getToken()) {
    navigate(route)
  }
}

export default navigateIfNotLoggedIn
