import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Alert, Snackbar, useTheme } from '@mui/material'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import {
  ENV,
  ROUTE_BUSINESS_ACTIVITY,
  ROUTE_ACCOUNT_CREATION,
  ROUTE_API_OTP_RESEND,
  ROUTE_API_OTP_VALIDATE,
  ROUTE_PHONE,
  ROUTE_API_OTP_BACK_REVALIDATE,
  ROUTE_API_OTP_SEND,
  ROUTE_API_USER_PHONE,
} from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import GenericDialog from 'components/GenericDialog'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { useUserFormContext } from 'components/Form/store/user'

import OTP, { reset } from 'components/Form/InputOTP'

import DIALOGS from 'constants/dialogs.json'
import DATA from 'constants/pages/validationFormPhone.json'
import { CheckMark, ErrorIcon } from 'components/Icons'
import segment from 'helpers/segment'
import { formatPhoneNumber, isWebView, randomString } from 'utils'
import { postInfo, updateInfo } from 'helpers/fetchUtils'

const ValidationFormPhonePage = () => {
  let errorCount = 0
  const theme = useTheme()
  const navigate = useNavigate()
  const { device } = useGlobalContext()
  const { userState } = useUserFormContext()

  const [maxRetriesDialog, setMaxRetriesDialog] = useState(false)
  const [expiredCodeDialog, setExpiredCodeDialog] = useState(false)

  const [codeResendSnackbar, setCodeResendSnackbar] = useState(false)
  const [snackbarError, setSnackbarError] = useState(false)

  const resend = () => {
    postInfo(ROUTE_API_OTP_SEND, {
      data: sessionStorage.getItem('phone'),
      source: 'onboarding',
      type: randomString(),
    })
      .then((data) => {
        sessionStorage.setItem('nonce', data?.data.nonce)
        reset()
      })
      .catch(() => setSnackbarError(true))
  }

  useEffect(() => {
    if (!sessionStorage.getItem('phone')) {
      navigate(ROUTE_ACCOUNT_CREATION)
    } else if (!sessionStorage.getItem('nonce')) {
      navigate(ROUTE_PHONE)
    }
  }, [])

  useEffect(() => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const triggerError = () => {
    setSnackbarError(true)
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
      ErrorMessage: DATA.CLEVERTAP.TARGET.error,
      OS: device.type,
    })
  }

  const nextPage = () => {
    updateInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_USER_PHONE}`, {
      mobile: sessionStorage.getItem('phone'),
    })
      .then(() => {
        segment.identify(userState.mid, {
          email: userState.email,
          phone: sessionStorage.getItem('phone'),
        })

        if (userState.phoneMarketing) {
          segment.track(
            DATA.SEGMENT.EVENTS.VALID_PHONE_NUMBER,
            DATA.SEGMENT.PROPERTY.ACCEPT_PROMOTIONS
          )
        }

        sessionStorage.removeItem('phone')
        sessionStorage.removeItem('nonce')
        navigate(
          !isWebView() ? ROUTE_BUSINESS_ACTIVITY : ROUTE_BUSINESS_ACTIVITY
        )
      })
      .catch(() => {
        triggerError()
      })
  }

  return (
    <Layout header={DATA.header} skipCallback={nextPage}>
      <StepWrapper
        content={DATA.content}
        controls={DATA.controls}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
      >
        <>
          <Typography sx={{ mb: 4 }} variant="body2">
            {DATA.subtitle}{' '}
            <strong style={{ whiteSpace: 'nowrap' }}>
              {formatPhoneNumber(sessionStorage.getItem('phone'))}.
            </strong>
          </Typography>
          <form id="otp-form">
            <OTP
              autofocus
              data={{
                delivery_mechanism: 'SMS',
                nonce: sessionStorage.getItem('nonce'),
                source: sessionStorage.getItem('phone'),
              }}
              endpoints={{
                resend: ROUTE_API_OTP_RESEND,
                validate: ROUTE_API_OTP_VALIDATE,
              }}
              id="phone-otp"
              labels={DATA.labels}
              onError={(data) => {
                errorCount += 1

                if (data?.status.status === 'EXPIRED') {
                  cleverTap.event(DATA.CLEVERTAP.EVENTS, {
                    ActionType: DATA.CLEVERTAP.ERROR,
                    OS: device.type,
                    Target: DATA.CLEVERTAP.TARGET.expired,
                  })

                  segment.track(DATA.SEGMENT.EVENTS.EXPIRED_CODE)

                  if (errorCount < 3) {
                    setExpiredCodeDialog(true)
                  }
                } else if (data?.status.status === 'INVALID') {
                  cleverTap.event(DATA.CLEVERTAP.EVENTS, {
                    ActionType: DATA.CLEVERTAP.ERROR,
                    OS: device.type,
                    Target: DATA.CLEVERTAP.TARGET.invalid,
                  })

                  segment.track(DATA.SEGMENT.EVENTS.INVALID_CODE)
                } else {
                  setSnackbarError(true)
                }

                if (errorCount >= 3) {
                  setMaxRetriesDialog(true)
                  errorCount = 0
                }

                document.querySelectorAll("input[type='tel']")[0].focus()
              }}
              onMaxRetries={() => {}}
              onResend={() => {
                errorCount = 0
                setCodeResendSnackbar(true)
              }}
              onSuccess={() => {
                segment.track(DATA.SEGMENT.EVENTS.SIGNUP_SUCCESS)

                postInfo(ROUTE_API_OTP_BACK_REVALIDATE, {
                  nonce: sessionStorage.getItem('nonce'),
                  phone: sessionStorage.getItem('phone'),
                })
                  .then(() => {
                    nextPage()
                  })
                  .catch(() => setSnackbarError(true))
              }}
              resendTimeout={60000}
              sx={{
                width: '100%',
              }}
            />
          </form>

          <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            autoHideDuration={DATA.toastTimeout}
            onClose={() => setCodeResendSnackbar(false)}
            open={codeResendSnackbar}
            sx={{ mb: 10 }}
          >
            <Alert
              icon={<CheckMark />}
              onClose={() => setCodeResendSnackbar(false)}
              severity="success"
              slotProps={{
                closeButton: {
                  sx: {
                    display: 'none',
                  },
                },
              }}
              sx={{ width: '100%' }}
            >
              {DATA.retrySuccess}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            autoHideDuration={DATA.toastTimeout}
            onClose={() => setSnackbarError(false)}
            open={snackbarError}
            sx={{ mb: 10 }}
          >
            <Alert
              icon={<ErrorIcon />}
              onClose={() => setSnackbarError(false)}
              severity="error"
              slotProps={{
                closeButton: {
                  sx: {
                    display: 'none',
                  },
                },
              }}
              sx={{ width: '100%' }}
            >
              {DATA.errorRevalidate}
            </Alert>
          </Snackbar>
          {/* MAX RETRIES CODE */}
          <GenericDialog
            callback={() => {
              resend()
              setMaxRetriesDialog(false)
            }}
            content={DIALOGS.retryExceededSMS}
            onClose={() => navigate(ROUTE_PHONE)}
            open={maxRetriesDialog}
            outsideClick={(e) => e.stopPropagation()}
          >
            <Typography sx={{ mt: 1 }} variant="body1">
              {DIALOGS.retryExceededSMS.title}
            </Typography>
            <Typography
              align="center"
              fontWeight={theme.fontWeight.regular}
              sx={{ padding: `${theme.space[3]}px ${theme.space[5]}px` }}
              variant="body1"
            >
              {DIALOGS.retryExceededSMS.description}
            </Typography>
          </GenericDialog>
          {/* EXPIRED CODE */}
          <GenericDialog
            callback={() => {
              resend()
              setExpiredCodeDialog(false)
            }}
            content={DIALOGS.expiredSMS}
            open={expiredCodeDialog}
          >
            <Typography sx={{ mt: 1 }} variant="body1">
              {DIALOGS.expiredSMS.title}
            </Typography>
            <Typography
              align="center"
              fontWeight={theme.fontWeight.regular}
              sx={{ padding: `${theme.space[3]}px ${theme.space[5]}px` }}
              variant="body1"
            >
              {DIALOGS.expiredSMS.description}
            </Typography>
          </GenericDialog>
        </>
      </StepWrapper>
    </Layout>
  )
}

export default ValidationFormPhonePage
