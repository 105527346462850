import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Layout from 'components/Layout'
import Image from 'components/Image'
import Paper from 'components/Paper'
import Button from 'components/Button'
import StepWrapper from 'components/StepWrapper'
import { Arrow, Chevron } from 'components/Icons'
import Typography from '@mui/material/Typography'
import DATA from 'constants/pages/firstTransactionReader.json'
import {
  ROUTE_FIRST_TRANSACTION,
  ROUTE_FIRST_TRANSACTION_TUTORIAL,
} from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { firstCardTransaction } from 'helpers/callNativeBridge'
import segment from 'helpers/segment'
import generateKey from 'utils/generateKey'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const FirstTransactionReader = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const handleBack = () => {
    navigate(ROUTE_FIRST_TRANSACTION)
  }

  const handleNativeBridge = () => {
    firstCardTransaction()
  }

  const handleClick = (it) => {
    segment.track(DATA.SEGMENT.EVENTS.SELECTED, { POS_choice: it.property })
    if (it.hasBridge) {
      handleNativeBridge()
    } else {
      navigate(ROUTE_FIRST_TRANSACTION_TUTORIAL, {
        state: {
          device: it.text,
        },
      })
    }
  }

  return (
    <Layout>
      <Stack sx={{ mb: 1, p: 0, width: '100%' }}>
        <Button onClick={handleBack} sx={{ justifyContent: 'flex-start' }}>
          <Arrow size={1} />
        </Button>
      </Stack>
      <StepWrapper>
        <Stack sx={{ mt: '-28px' }}>
          <Typography
            fontWeight={theme.fontWeight.bold}
            sx={{ mb: 1.5 }}
            variant="body5"
          >
            {DATA.title}
          </Typography>
          <Typography
            color="surface.c700"
            fontWeight={theme.fontWeight.regular}
            variant="h4"
          >
            {DATA.subtitle}
          </Typography>
          {DATA.buttons.map((item, idx) => (
            <Paper key={generateKey(idx)} sx={{ mt: 2.5, p: 0 }}>
              <Button
                onClick={() => handleClick(item)}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Image alt={item.text} src={item.image} />
                  <Typography
                    align="left"
                    color="surface.c800"
                    fontWeight={theme.fontWeight.semibold}
                    variant="body2"
                  >
                    {item.text}
                  </Typography>
                </Stack>
                <Chevron size={1.8} />
              </Button>
            </Paper>
          ))}
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default FirstTransactionReader
