import { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Paper from 'components/Paper'
import FormText from 'components/Form/Text'
import { StackBusinessAddress } from 'components/Renders'
import { Arrow } from 'components/Icons'
import DATA from 'constants/pages/assisted.json'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { ROUTE_ASSISTED } from 'constants/routes'
import { useFormik } from 'formik'
import * as yup from 'yup'
import segment from 'helpers/segment'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const AssistedEditDataInfo = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const phoneValidation = yup
    .string()
    .trim()
    .required(DATA.REQUIRED)
    .matches(/^.{10}$/, DATA.ERROR_LENGTH)
    .matches(/^[0-9]+$/, DATA.ONLY_NUMBERS)

  const nameValidation = yup
    .string()
    .trim()
    .matches(/^[A-Za-zÀ-ÿ.\s\u00f1\u00d1\u0027]+$/, DATA.INVALID_ENTRY)

  const VALIDATION = yup.object().shape({
    [DATA.FORMIK_ID.NAMES]: nameValidation.required(DATA.REQUIRED),
    [DATA.FORMIK_ID.LAST_NAME]: nameValidation.required(DATA.REQUIRED),
    [DATA.FORMIK_ID.SECONDARY_LAST_NAME]: nameValidation,
    [DATA.FORMIK_ID.PHONE]: phoneValidation,
  })

  const formik = useFormik({
    initialValues: {
      [DATA.FORMIK_ID.NAMES]: '',
      [DATA.FORMIK_ID.LAST_NAME]: '',
      [DATA.FORMIK_ID.SECONDARY_LAST_NAME]: '',
      [DATA.FORMIK_ID.PHONE]: '',
    },
    onSubmit: (values) => {
      const payload = {
        first_name: values[DATA.FORMIK_ID.NAMES].replace(/\s+/g, ' ').trim(),
        last_name: values[DATA.FORMIK_ID.LAST_NAME].replace(/\s+/g, ' ').trim(),
        mobile: values[DATA.FORMIK_ID.PHONE].toString(),
        second_last_name: values[DATA.FORMIK_ID.SECONDARY_LAST_NAME]
          .replace(/\s+/g, ' ')
          .trim(),
      }

      sessionStorage.setItem(DATA.SESSION_USER_DATA, JSON.stringify(payload))
      segment.track(DATA.SEGMENT.EVENTS.EDIT_PERSONAL_INFO)
      navigate(ROUTE_ASSISTED)
    },
    validationSchema: VALIDATION,
  })

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    const data = JSON.parse(sessionStorage.getItem(DATA.SESSION_USER_DATA))

    if (data) {
      formik.setFieldValue(DATA.FORMIK_ID.NAMES, data?.first_name)
      formik.setFieldValue(DATA.FORMIK_ID.LAST_NAME, data?.last_name)
      formik.setFieldValue(
        DATA.FORMIK_ID.SECONDARY_LAST_NAME,
        data?.second_last_name
      )
      formik.setFieldValue(DATA.FORMIK_ID.PHONE, data?.mobile)
    }
  }, [])

  return (
    <Layout>
      <Stack sx={{ mb: 1, p: 0, width: '100%' }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{ justifyContent: 'flex-start' }}
        >
          <Arrow size={1} />
        </Button>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
          width: '100%',
        }}
      >
        <Box>
          <Stack spacing={1.5} sx={{ mb: 3 }}>
            <Typography fontWeight={theme.fontWeight.bold} variant="body5">
              {DATA.PERSONAL_DATA.TITLE}
            </Typography>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              {DATA.PERSONAL_DATA.DESCRIPTION}
            </Typography>
          </Stack>
          <Typography
            fontWeight={theme.fontWeight.bold}
            sx={{ mb: 2 }}
            variant="body2"
          >
            {DATA.PERSONAL_DATA.NAME}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Paper
              sx={{
                mb: 3,
                p: 0,
              }}
            >
              <StackBusinessAddress
                sx={{
                  '& .MuiFormControl-root': {
                    m: 'auto',
                    width: 'calc(100% - 16px)',
                  },
                  '& .MuiInput-root, & .MuiInputBase-input[value]': {
                    pl: 0,
                  },
                }}
              >
                <FormText
                  autoFocus
                  error={
                    formik.touched[DATA.FORMIK_ID.NAMES] &&
                    formik.errors[DATA.FORMIK_ID.NAMES]
                  }
                  errorHelperIcon
                  id={DATA.FORMIK_ID.NAMES}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.PERSONAL_DATA.NAMES}
                  onChange={formik.handleChange}
                  value={formik.values[DATA.FORMIK_ID.NAMES]}
                  variant="standard"
                />
                <FormText
                  error={
                    formik.touched[DATA.FORMIK_ID.LAST_NAME] &&
                    formik.errors[DATA.FORMIK_ID.LAST_NAME]
                  }
                  errorHelperIcon
                  id={DATA.FORMIK_ID.LAST_NAME}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.PERSONAL_DATA.LAST_NAME}
                  onChange={formik.handleChange}
                  value={formik.values[DATA.FORMIK_ID.LAST_NAME]}
                  variant="standard"
                />
                <Box
                  sx={{
                    '& .MuiInputBase-root.MuiInput-root:not(.Mui-error):before, & .MuiInputBase-root.MuiInput-root:not(.Mui-error):after':
                      {
                        borderBottom: 'none',
                      },
                    ml: 1,
                    p: 0,
                  }}
                >
                  <FormText
                    error={
                      formik.touched[DATA.FORMIK_ID.SECONDARY_LAST_NAME] &&
                      formik.errors[DATA.FORMIK_ID.SECONDARY_LAST_NAME]
                    }
                    errorHelperIcon
                    id={DATA.FORMIK_ID.SECONDARY_LAST_NAME}
                    InputProps={{
                      endAdornment: <>&nbsp;</>,
                    }}
                    label={DATA.PERSONAL_DATA.SECONDARY_LAST_NAME}
                    onChange={formik.handleChange}
                    value={formik.values[DATA.FORMIK_ID.SECONDARY_LAST_NAME]}
                    variant="standard"
                  />
                </Box>
              </StackBusinessAddress>
            </Paper>

            <Typography
              fontWeight={theme.fontWeight.bold}
              sx={{ mb: 2 }}
              variant="body2"
            >
              {DATA.PERSONAL_DATA.PHONE_NUMBER}
            </Typography>
            <Paper sx={{ mb: 3, p: 0 }}>
              <StackBusinessAddress
                sx={{
                  '& .MuiFormControl-root': {
                    m: 'auto',
                    width: 'calc(100% - 16px)',
                  },
                  '& .MuiInput-root': {
                    pl: 0,
                  },
                  '& .MuiInputBase-root.MuiInput-root:not(.Mui-error):before, & .MuiInputBase-root.MuiInput-root:not(.Mui-error):after':
                    {
                      border: 'none',
                    },
                }}
              >
                <FormText
                  error={
                    formik.touched[DATA.FORMIK_ID.PHONE] &&
                    formik.errors[DATA.FORMIK_ID.PHONE]
                  }
                  errorHelperIcon
                  id={DATA.FORMIK_ID.PHONE}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.PERSONAL_DATA.PHONE_NUMBER}
                  maxLength="10"
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values[DATA.FORMIK_ID.PHONE]}
                  variant="standard"
                />
              </StackBusinessAddress>
            </Paper>
          </form>
        </Box>
        <Button
          color="orange"
          onClick={formik.handleSubmit}
          size="full-width"
          sx={{
            height: '48px',
          }}
          variant="primary-form"
        >
          {DATA.SAVE}
        </Button>
      </Box>
    </Layout>
  )
}

export default AssistedEditDataInfo
