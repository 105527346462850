import { useEffect, useState } from 'react'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import { useNavigate } from 'react-router-dom'
import FormPhone from 'components/Form/Phone'
import { ROUTE_API_OTP_SEND, ROUTE_VALIDATION_PHONE } from 'constants/routes'
import { actions, useUserFormContext } from 'components/Form/store/user'
import DATA from 'constants/pages/phoneForm.json'
import SubmitError from 'components/SubmitError'
import { postInfo } from 'helpers/fetchUtils'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import segment from 'helpers/segment'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const PhoneFormPage = () => {
  const navigate = useNavigate()
  const { device } = useGlobalContext()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState()
  const { userDispatch } = useUserFormContext()
  const inputId = DATA.FORMIKS_ID.INPUT_ID

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  useEffect(() => {
    userDispatch({
      phoneMarketing: isChecked,
      type: actions.UPDATE_PHONE_MARKETING,
    })
  }, [isChecked])

  const sendCTError = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
      ErrorMessage: DATA.CLEVERTAP.TARGET.invalid,
      OS: device.type,
    })
    segment.track(DATA.SEGMENT.EVENTS.INVALID_PHONE_NUMBER)
  }

  const testIncomplete = (value) => {
    const returnValue = value?.length >= 10

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const testRepetitive = (value) => {
    const returnValue = !value?.match(/(\d)\1{8}/)

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const testSecuential = (value) => {
    const returnValue = !value?.match(/(?:1234567890|0123456789)/)

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const PHONE_VALIDATION = yup.object().shape({
    [inputId]: yup
      .string()
      .test('test-non-empty', DATA.emptyLabel, (value) => !!value)
      .test('test-incomplete', DATA.incompleteLabel, (value) =>
        testIncomplete(value)
      )
      .test('test-repetitive', DATA.invalidLabel, (value) =>
        testRepetitive(value)
      )
      .test('test-secuential', DATA.invalidLabel, (value) =>
        testSecuential(value)
      ),
  })

  const triggerError = () => {
    setLoading(false)
    setOpenSnackbar(true)
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
      ErrorMessage: DATA.CLEVERTAP.TARGET.error,
      OS: device.type,
    })
  }

  const formik = useFormik({
    initialValues: {
      [inputId]: '',
    },
    onSubmit: (values) => {
      setLoading(true)
      sessionStorage.setItem('phone', values[inputId])

      postInfo(ROUTE_API_OTP_SEND, {
        data: values[inputId],
        source: 'onboarding',
        type: 'phone_validation',
      })
        .then((data) => {
          sessionStorage.setItem('nonce', data?.data.nonce)
          segment.track(DATA.SEGMENT.EVENTS.ADD_PHONE_NUMBER)

          navigate(ROUTE_VALIDATION_PHONE)
        })
        .catch(() => triggerError())
    },
    validateOnChange: false,
    validationSchema: PHONE_VALIDATION,
  })

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        eventCleverTap={DATA.CLEVERTAP.PAGE}
        loading={loading}
        onContinue={formik.handleSubmit}
        sx={{ '& > .MuiStack-root': { flex: 1, pb: 2 } }}
      >
        <Box
          sx={{
            '&.MuiBox-root form': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            display: 'flex',
            flex: 1,
            pb: 4,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <FormPhone
              error={formik.touched[inputId] && formik.errors[inputId]}
              id={inputId}
              label={DATA.label}
              onChange={formik.handleChange}
              value={formik.values[inputId]}
            />
            <FormControlLabel
              checked={isChecked}
              control={<Checkbox />}
              label={
                <Typography
                  color="surface.c700"
                  fontWeight={(theme) => theme.fontWeight.regular}
                  variant="body1"
                >
                  {DATA.checkboxLabel}
                </Typography>
              }
              onChange={() => setIsChecked(!isChecked)}
              value={isChecked}
            />
          </form>
          <SubmitError
            onClose={() => setOpenSnackbar(false)}
            open={openSnackbar}
          />
        </Box>
      </StepWrapper>
    </Layout>
  )
}

export default PhoneFormPage
