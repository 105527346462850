import axios from 'axios'
import { getToken } from './tokenHelpers'

/**
 * Returns an options obj with necessary headers to make an authenticated
 * request to API Gateway
 *
 * */
export const getRequestOptions = () => {
  const token = getToken()

  const headers = {
    Accept: 'application/vnd.com.payclip.v1+json',
  }

  if (token) {
    headers.Authorization = token
  }

  return { headers }
}

/**
 * Returns a promise with the http fetch request results
 * */
export const fetchInfo = (url) => {
  const options = getRequestOptions(url)

  return axios.get(url, options)
}

/**
 * Returns a promise with the http fetch request results
 * */
export const postInfo = async (url, body) => {
  const options = getRequestOptions(url)

  return axios.post(url, body, options)
}

export const updateInfo = async (url, payload) => {
  const options = getRequestOptions(url)

  return axios.patch(url, payload, options)
}
