import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Paper from 'components/Paper'
import LoadingButton from 'components/LoadingButton'
import FormText from 'components/Form/Text'
import { StackBusinessAddress } from 'components/Renders'
import { Chevron } from 'components/Icons'
import DATA from 'constants/pages/assisted.json'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import {
  ENV,
  ROUTE_ASSISTED_EDIT_BI,
  ROUTE_ASSISTED_EDIT_UI,
  ROUTES_API_ASSISTED_GET,
  ROUTES_API_ASSISTED_POST,
  ROUTE_ACCOUNT_ACTIVATION,
} from 'constants/routes'
import { fetchInfo, postInfo } from 'helpers/fetchUtils'
import segment from 'helpers/segment'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'
import { getToken } from 'helpers/tokenHelpers'

const getUrl = `${ENV.REACT_APP_API_URL}${ROUTES_API_ASSISTED_GET}`
const postUrl = `${ENV.REACT_APP_API_URL}${ROUTES_API_ASSISTED_POST}`

const AssistedPage = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [loading, setLoading] = useState()
  const [info, setInfo] = useState()
  const [userData, setUserData] = useState()
  const [businessData, setBusinessData] = useState()
  const [msgError, setMsgError] = useState()

  useEffect(() => {
    if (!getToken()) {
      navigateIfNotLoggedIn(navigate)
    } else {
      fetchInfo(getUrl).then(({ data }) => {
        setInfo(data)
        setBusinessData(
          sessionStorage.getItem(DATA.SESSION_BUSINESS_DATA)
            ? JSON.parse(sessionStorage.getItem(DATA.SESSION_BUSINESS_DATA))
            : data?.business
        )
        setUserData(
          sessionStorage.getItem(DATA.SESSION_USER_DATA)
            ? JSON.parse(sessionStorage.getItem(DATA.SESSION_USER_DATA))
            : data?.user
        )
      })
      segment.page(DATA.SEGMENT.PAGE)
    }
  }, [])

  const editBusinessInfo = () => {
    sessionStorage.setItem(
      DATA.SESSION_BUSINESS_DATA,
      JSON.stringify(businessData)
    )
    navigate(ROUTE_ASSISTED_EDIT_BI)
  }

  const editUserInfo = () => {
    sessionStorage.setItem(DATA.SESSION_USER_DATA, JSON.stringify(userData))
    navigate(ROUTE_ASSISTED_EDIT_UI)
  }

  const handleSubmit = () => {
    setLoading(true)

    const payload = {
      business: {
        address: {
          colony: businessData?.address?.colony,
          external_number: businessData?.address?.external_number,
          internal_number: businessData?.address?.internal_number,
          municipality: businessData?.address?.municipality,
          postal_code: businessData?.address?.postal_code,
          state: businessData?.address?.state,
          street: businessData?.address?.street,
          type: 'EXTERNAL',
          version_flow: 'EMAIL_FLOW',
        },
        industry_id: businessData?.industry_id,
        name: businessData?.name,
      },
      source: info?.source,
      tac: info?.tac,
      user: {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        mobile: userData?.mobile,
        second_last_name: userData?.second_last_name,
      },
    }

    postInfo(postUrl, payload)
      .then(() => {
        segment.track(DATA.SEGMENT.EVENTS.CONFIRM_ASSISTED)
        navigate(ROUTE_ACCOUNT_ACTIVATION)
      })
      .catch((err) => {
        setLoading(false)

        if (err.response.status === 500) {
          setMsgError(DATA.ERROR)
        } else {
          setMsgError(DATA.SERVICE_ERRORS[err.response.data] || DATA.ERROR)
        }
        segment.track(DATA.SEGMENT.EVENTS.ERROR_ASSISTED)
      })
  }

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
          width: '100%',
        }}
      >
        <Box>
          <Stack spacing={1.5}>
            <Typography fontWeight={theme.fontWeight.bold} variant="body5">
              {DATA.TITLE.HOME}
            </Typography>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              {DATA.DESCRIPTION}
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ alignItems: 'center', mt: 3 }}
          >
            <Typography fontWeight={theme.fontWeight.bold} variant="body2">
              {DATA.PERSONAL_DATA.SECTION_TITLE}
            </Typography>
            <Button
              onClick={editUserInfo}
              sx={{
                alignItems: 'center',
                fontWeight: theme.fontWeight.bold,
                justifyContent: 'flex-end',
                pr: 0,
              }}
            >
              {DATA.EDIT}
              <Chevron color={theme.palette.primary.main} />
            </Button>
          </Stack>
          <Paper sx={{ p: 0, pb: 1 }}>
            <StackBusinessAddress
              sx={{
                '& .MuiFormControl-root': {
                  m: 'auto',
                  width: 'calc(100% - 16px)',
                },
                '& .MuiFormControl-root .MuiButtonBase-root': {
                  margin: '8px auto auto',
                  paddingLeft: 0,
                  width: 'calc(100% - 13px)',
                },
                '& .MuiInput-root': {
                  pl: 0,
                },
                '& .MuiInputBase-root.MuiInput-root:before, & .MuiInputBase-root.MuiInput-root:after':
                  {
                    border: 'none',
                  },
              }}
            >
              <FormText
                behavior="uneditable"
                label={DATA.PERSONAL_DATA.NAME}
                value={`${userData?.first_name || ''} ${
                  userData?.last_name || ''
                } ${userData?.second_last_name || ''} `}
                variant="standard"
              />
              <FormText
                behavior="uneditable"
                label={DATA.PERSONAL_DATA.PHONE_NUMBER}
                value={userData?.mobile || ''}
                variant="standard"
              />
            </StackBusinessAddress>
          </Paper>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ alignItems: 'center', mt: 3 }}
          >
            <Typography fontWeight={theme.fontWeight.bold} variant="body2">
              {DATA.BUSINESS_DATA.SECTION_TITLE}
            </Typography>
            <Button
              onClick={editBusinessInfo}
              sx={{
                alignItems: 'center',
                fontWeight: theme.fontWeight.bold,
                justifyContent: 'flex-end',
                pr: 0,
              }}
            >
              {DATA.EDIT}
              <Chevron color={theme.palette.primary.main} />
            </Button>
          </Stack>
          <Paper sx={{ p: 0, pb: 1 }}>
            <StackBusinessAddress
              sx={{
                '& .MuiFormControl-root': {
                  m: 'auto',
                  width: 'calc(100% - 16px)',
                },
                '& .MuiFormControl-root .MuiButtonBase-root': {
                  margin: '8px auto auto',
                  paddingLeft: 0,
                  width: 'calc(100% - 13px)',
                },
                '& .MuiInput-root': {
                  pl: 0,
                },
                '& .MuiInputBase-root.MuiInput-root:before, & .MuiInputBase-root.MuiInput-root:after':
                  {
                    border: 'none',
                  },
              }}
            >
              <FormText
                behavior="uneditable"
                label={DATA.BUSINESS_DATA.BUSINESS_NAME}
                value={businessData?.name || ''}
                variant="standard"
              />
              <FormText
                behavior="uneditable"
                label={DATA.BUSINESS_DATA.BUSINESS_ADDRESS}
                value={`${businessData?.address?.street || ''} ${
                  businessData?.address?.external_number || ''
                }, ${businessData?.address?.postal_code || ''}, ${
                  businessData?.address?.municipality || ''
                }, ${businessData?.address?.colony || ''}, ${
                  businessData?.address?.state || ''
                }`}
                variant="standard"
              />
            </StackBusinessAddress>
          </Paper>
        </Box>
        {msgError && (
          <Typography color="error.main" sx={{ mb: 2, mt: 2 }}>
            {msgError}
          </Typography>
        )}

        <LoadingButton
          color="orange"
          loading={loading}
          onClick={handleSubmit}
          size="full-width"
          sx={{
            '&.Mui-disabled': {
              '&.MuiLoadingButton-loading': {
                backgroundColor: loading && theme.backgrounds[10],
              },
            },
            height: '48px',
            mt: 4,
          }}
          variant="primary-form"
        >
          {DATA.CONFIRM}
        </LoadingButton>
      </Box>
    </Layout>
  )
}

export default AssistedPage
