import KYC from 'constants/kyc'

const isKycAccepted = (status) => {
  const subSteps = status?.userData?.subSteps
  const kycSubstep = subSteps?.find((e) => e.stepId === 'kycCompleted')

  if (!subSteps) {
    console.error('No subSteps key found!')
  }

  return kycSubstep?.status === KYC.ACCEPTED
}

export default isKycAccepted
