import { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTE_BUSINESS_ADDRESS } from 'constants/routes'
import { Actions, useBusinessFormContext } from 'components/Form/store/business'
import AddressAutocomplete from 'components/AddressAutocomplete'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import DATA from 'constants/pages/businessAddressChoose.json'
import { useTheme } from '@mui/material'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const BusinessAddressChoosePage = () => {
  const { device } = useGlobalContext()
  const navigate = useNavigate()
  const { businessDispatch } = useBusinessFormContext()
  const [query, setQuery] = useState('')
  const theme = useTheme()

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    segment.page(DATA.SEGMENT.PAGE)

    businessDispatch({
      business_address: null,
      type: Actions.UPDATE_BUSINESS_ADDRESS,
    })
  }, [])

  const handleOpen = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.GMAPS_OP_SEL,
    })
  }

  const handleContinue = (data) => {
    segment.track(DATA.SEGMENT.EVENTS.SELECT_ADDRESS_FROM_SEARCH_RESULTS)
    businessDispatch({
      business_address: {
        ...data,
        source: DATA.flagGoogle.source,
      },
      type: Actions.UPDATE_BUSINESS_ADDRESS,
    })

    navigate(ROUTE_BUSINESS_ADDRESS)
  }

  const handleClick = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.MANUAL_ADDRESS_SEL,
    })
    segment.track(DATA.SEGMENT.EVENTS.GO_TO_INPUT_MANUAL_ADDRESS)

    if (query !== '') {
      let endIndex = 0
      let value = ''

      if (query.trim().lastIndexOf(' ') > 0) {
        endIndex = query.trim().lastIndexOf(' ')
        value = !parseInt(
          query.trim().substring(endIndex, query.trim().length),
          10
        )
          ? query
          : query.substring(0, endIndex)
      } else {
        value = query
      }

      businessDispatch({
        business_address: {
          ...{ street: value },
        },
        type: Actions.UPDATE_BUSINESS_ADDRESS,
      })
    }
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper content={DATA.content}>
        <>
          <Box data-testid="search-trigger" onClick={handleOpen}>
            <AddressAutocomplete
              onSelect={handleContinue}
              placeholder={DATA.autoFill}
              query={query}
              setQuery={setQuery}
            />
          </Box>
          <Typography color="surface.c700" sx={{ mt: 1 }} variant="body0">
            {DATA.content.footnote}
          </Typography>
          <Stack direction="row" spacing={0.5} sx={{ mt: 2 }}>
            <Link
              onClick={handleClick}
              style={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
              to={ROUTE_BUSINESS_ADDRESS}
            >
              {DATA.manualFill}{' '}
              <Typography color="primary.main">
                {DATA.manualFillLink}
              </Typography>
            </Link>
          </Stack>
        </>
      </StepWrapper>
    </Layout>
  )
}

export default BusinessAddressChoosePage
