import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
  Link,
  FormHelperText,
} from '@mui/material'
import { useUserFormContext } from 'components/Form/store/user'
import { randomString } from 'utils'
import DATA from './data.json'
import { ErrorHelper } from 'components/Icons'
import { useState } from 'react'

const Tac = (props) => {
  const { onChange, initialValues, isInvalid, sx } = props
  const theme = useTheme()
  const [isChecked, setIsChecked] = useState(initialValues)
  const inputsId = [
    DATA.ids.TAC_PRIVACY_INPUT_ID,
    DATA.ids.CONTRACT_WALLET_INPUT_ID,
    DATA.ids.TAC_PRIVACY_WALLET_INPUT_ID,
  ]

  const { userState } = useUserFormContext()

  const showWalletCheckboxes = userState.kycAccepted

  const labelStyles = {
    '& strong': {
      color: theme.palette.primary.main,
    },
    fontWeight: theme.fontWeight.regular,
  }

  const items = showWalletCheckboxes ? DATA.wallet : DATA.simple

  const renderLink = (link) => (
    <Link
      className="rendered-link"
      href={link.href}
      sx={{
        color: theme.palette.primary.main,
        fontWeight: theme.fontWeight.bold,
        textDecoration: 'none',
      }}
      target="_blank"
    >
      {link.linkText}
    </Link>
  )

  const renderCheckboxes = (checkboxItems) => (
    <Stack
      sx={{
        '& .MuiFormHelperText-root.Mui-error': { fontSize: '14px' },
        ...sx,
      }}
    >
      {checkboxItems.map((item, index) => (
        <FormControlLabel
          key={randomString()}
          checked={isChecked[index]}
          control={
            <Checkbox
              data-testid={`tac-checkbox-${item.value}`}
              id={inputsId[index]}
              sx={{
                color: isInvalid
                  ? theme.palette.error.main
                  : theme.palette.surface.c500,
              }}
            />
          }
          label={
            <Typography
              data-testid={`tac-label-${item.value}`}
              sx={labelStyles}
              variant="body1"
            >
              {item.label}
              {renderLink(item.links[0])}
              {item.links[1] && (
                <>
                  {item.connectorText}
                  {renderLink(item.links[1])}
                </>
              )}
            </Typography>
          }
          name={inputsId[index]}
          onChange={(e) => {
            const updatedArray = [...isChecked]

            updatedArray[index] = !isChecked[index]
            setIsChecked(updatedArray)

            onChange({
              items: updatedArray,
              target: {
                checked: isChecked[index],
                labels: item.label,
                name: e.target.name,
                value: isChecked[index],
              },
            })
          }}
          sx={{ ml: 0 }}
          value={isChecked[index]}
        />
      ))}
      <FormHelperText
        component="div"
        error={isInvalid}
        sx={{
          display: isInvalid ? null : 'none',
        }}
      >
        <Stack alignItems="center" direction="row">
          <ErrorHelper ml={2} mr={1} />
          {showWalletCheckboxes ? DATA.errorWallet : DATA.error}
        </Stack>
      </FormHelperText>
    </Stack>
  )

  return <Stack spacing={2}>{renderCheckboxes(items)}</Stack>
}

export default Tac

Tac.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.bool),
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.shape({}),
}

Tac.defaultProps = {
  initialValues: [false, false, false],
  isInvalid: false,
  onChange: null,
  sx: null,
}
