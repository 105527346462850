/* eslint-disable no-undef */
import { getCurrentDomain } from 'utils'
import { ROUTE_SYNC, ROUTE_KYC_ERROR } from 'constants/routes'
import { getToken } from './tokenHelpers'

const getDataKYC = () => ({
  authToken: getToken(),
  documents: ['OFFICIAL_ID'], // -> optional
  onErrorCallbackURL: getCurrentDomain() + ROUTE_KYC_ERROR,
  onSuccessCallbackURL: getCurrentDomain() + ROUTE_SYNC,
  to: 'kyc',
})

const startNativeBridge = (event, param) => {
  const obj = param || getDataKYC()

  event.preventDefault()

  /* eslint-disable no-console */
  console.log('Webview KYC flow started! data:', obj)

  if (typeof Android !== 'undefined' && Android !== null) {
    const str = JSON.stringify(obj)

    /* eslint-disable no-console */
    console.log('Android: triggering KYC redirect!')

    Android.redirect(str)
  } else {
    try {
      if (typeof webkit !== 'undefined') {
        /* eslint-disable no-console */
        console.log('Webkit: triggering KYC redirect!')

        webkit.messageHandlers.redirect.postMessage(obj)
      }
    } catch {
      // Exception handlingç
      /* eslint-disable no-console */
      console.log('KYC error!')
    }
  }
}

const backMobileApp = () => {
  if (typeof Android !== 'undefined' && Android !== null) {
    console.log('try to close android webview')
    Android.closeWebView()
  } else {
    try {
      console.log('try to close IOS webview')
      webkit.messageHandlers.closeWebView.postMessage({})
    } catch (_unused2) {
      console.log('Error: redirect to appMobile')
      // Exception handling
    }
  }
}

const handleNativeShare = async (title, text, url) => {
  const shareData = {
    text,
    title,
    url,
  }

  /* eslint-disable no-console */
  console.log('Webview handleNativeShare! shareData:', shareData)

  try {
    if (typeof Android !== 'undefined' && Android !== null) {
      const str = JSON.stringify(shareData)

      Android.nativeShare(str)
    } else {
      await navigator.share(shareData)
    }

    return true
  } catch {
    return false
  }
}

const recordAppsFlyerEvent = (eventName, eventParams) => {
  try {
    if (typeof Android !== 'undefined' && Android !== null) {
      Android.recordEvent(eventName, eventParams)
    } else {
      webkit.messageHandlers.event.postMessage(`${eventName}+${eventParams}`)
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.log('Webview recordAppsFlyerEvent! error', error)
  }
}

const tokenBridge = () => {
  const obj = {
    authToken: getToken(),
  }

  try {
    if (typeof Android !== 'undefined' && Android !== null) {
      const str = JSON.stringify(obj)

      Android.authenticate(str)
    } else {
      webkit.messageHandlers.authenticate.postMessage(obj)
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.log('Webview tokenBridge! error', error)
  }
}

const firstCardTransaction = () => {
  if (typeof Android !== 'undefined' && Android !== null) {
    Android.firstCardTransaction()
  } else {
    try {
      webkit.messageHandlers.firstCardTransaction.postMessage({})
    } catch {
      // Exception handling
    }
  }
}

const firstPaymentLinkCreation = () => {
  if (typeof Android !== 'undefined' && Android !== null) {
    Android.firstPaymentLinkCreation()
  } else {
    try {
      webkit.messageHandlers.firstPaymentLinkCreation.postMessage({})
    } catch {
      // Exception handling
    }
  }
}

export {
  getDataKYC,
  startNativeBridge,
  handleNativeShare,
  recordAppsFlyerEvent,
  tokenBridge,
  firstCardTransaction,
  firstPaymentLinkCreation,
  backMobileApp,
}
