import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import {
  ENV,
  ROUTES_API_BANKS,
  ROUTES_API_CREATE_CLABE,
  ROUTE_INSTALLMENTS,
} from 'constants/routes'

import { fetchInfo, postInfo } from 'helpers/fetchUtils'

import Layout from 'components/Layout'
import Button from 'components/Button'
import StepWrapper from 'components/StepWrapper'
import SubmitError from 'components/SubmitError'
import SlideUpModal from 'components/SlideUpModal'
import ClabeForm from 'components/Form/Clabe'
import { actions, useUserFormContext } from 'components/Form/store/user'

import DATA from 'constants/pages/clabeForm.json'

import { Stack, useTheme, Typography, Box } from '@mui/material'
import segment from 'helpers/segment'

import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const ClabeFormPage = () => {
  const [bankCodeState, setBankCode] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSTP, setIsSTP] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { inputs } = DATA
  const inputsArray = Object.keys(inputs).map((key) => inputs[key])
  const baseURL = `${ENV.REACT_APP_API_URL_2}${ROUTES_API_BANKS}`
  const theme = useTheme()
  const { userDispatch } = useUserFormContext()
  const [cursorPosition, setCursorPosition] = useState(0)
  const [bankAccountNumberVal, setBankAccountNumberVal] = useState('')

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const formik = useFormik({
    initialValues: {
      bankAccountNumber: '',
      bankAccountOwnerName: '',
      bankName: '',
    },
    onSubmit: (values) => {
      setLoading(true)

      postInfo(`${ENV.REACT_APP_API_URL}${ROUTES_API_CREATE_CLABE}`, {
        bank_account_number: values.bankAccountNumber.replace(/\s/g, ''),
        bank_account_owner_name: values.bankAccountOwnerName,
        bank_name: values.bankName,
      })
        .then(() => {
          userDispatch({ clabe: true, type: actions.UPDATE_CLABE })
          setLoading(false)
          segment.track(DATA.SEGMENT.EVENTS.SAVE)

          navigate(ROUTE_INSTALLMENTS)
        })
        .catch((error) => {
          setLoading(false)

          if (!error.response) {
            const { code } = error.response.data.error

            switch (code) {
              case 708:
                // STP error
                setIsSTP(true)
                setErrorMessage(DATA.apiErrors.stp.text)
                break
              case 103:
                // Invalid CLABE
                setIsSTP(false)
                setErrorMessage(DATA.apiErrors.invalid.text)
                break
              default:
                setIsSTP(false)
                setErrorMessage(DATA.apiErrors.generic.text)
                break
            }
          } else {
            setIsSTP(false)
            setErrorMessage(DATA.apiErrors.generic.text)
          }

          segment.track(DATA.SEGMENT.EVENTS.ERROR)
          setOpenSnackbar(true)
        })
    },

    validationSchema: yup.object().shape({
      bankAccountNumber: yup
        .string()
        .matches(/^[0-9 ]+$/, 'Ingresa solo números')
        .length(22, 'La cuenta CLABE debe tener 18 dígitos.')
        .typeError('Ingresa solo números')
        .required('Completa este dato'),
      bankAccountOwnerName: yup
        .string()
        .matches(
          /^(?!.*\d.*\d.*\d.*\d.*\d.*\d)[0-9a-zA-ZáéíóúüñÁÉÍÓÚÜÑ.¨' ]{1,50}$/,
          'Escribe un nombre válido del titular de la cuenta.'
        )
        .required('Completa este dato'),
      bankName: yup.string().required('Ingresa el nombre del banco'),
    }),
  })

  useEffect(() => {
    if (bankCodeState.length >= 3) {
      const fetchBankName = async () => {
        const { data } = await fetchInfo(`${baseURL}?code=${bankCodeState}`)

        const bankName =
          Object.keys(data).length !== 0 ? data.name : 'Banco no encontrado'

        formik.setFieldValue('bankName', bankName)
      }

      fetchBankName()
    }
  }, [bankCodeState])

  useEffect(() => {
    const { length } = formik.values.bankAccountNumber
    const input = document.getElementById('bankAccountNumber')
    const previousValueLength = bankAccountNumberVal.length
    const spacesArray = [5, 10, 15, 20]
    const caretPosition =
      length > previousValueLength && spacesArray.includes(cursorPosition)
        ? cursorPosition + 1
        : cursorPosition

    input.setSelectionRange(caretPosition, caretPosition)
  }, [formik.values.bankAccountNumber])

  const handleSubmit = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  const handleInputChange = (e) => {
    const { id, value, selectionStart } = e.target
    const isBankAccountNumber = id === 'bankAccountNumber'
    let val = value

    if (isBankAccountNumber) {
      const bankCode = value.substring(0, 3)

      setBankAccountNumberVal(formik.values[id])
      setCursorPosition(selectionStart)

      const deletingSpace = value.length < formik.values[id]?.length

      val = value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')

      if (deletingSpace) {
        val = val.replace(/\d{0}\s?$/, '')
      }

      if (value.length >= 3 && bankCodeState !== bankCode) {
        setBankCode(bankCode)
      }
    }

    formik.setFieldValue(id, val)
  }

  const handleOmit = () => {
    segment.track(DATA.SEGMENT.EVENTS.OMIT)

    navigate(ROUTE_INSTALLMENTS)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleStpModal = () => {
    setOpenModal(true)
    setOpenSnackbar(false)
  }

  const renderStpLink = () => (
    <Button
      onClick={handleStpModal}
      sx={{
        color: theme.palette.error.main,
        fontSize: 12,
        textDecoration: 'underline',
        top: -2,
      }}
      type="link"
    >
      {DATA.apiErrors.stp.linkText}
    </Button>
  )

  return (
    <Layout>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '56px',
          justifyContent: 'flex-end',
          mb: 2,
          width: '100%',
        }}
      >
        <Button
          onClick={handleOmit}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            minWidth: 'auto',
            px: 2,
          }}
        >
          <Typography
            color="primary.main"
            fontWeight={theme.fontWeight.bold}
            variant="body2"
          >
            {DATA.content.omit}
          </Typography>
        </Button>
      </Box>
      <StepWrapper
        content={DATA.content}
        loading={loading}
        onContinue={handleSubmit}
        onContinueText={DATA.onContinueText}
        onLoadingText={DATA.onLoadingText}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {inputsArray.map((input) => (
              <ClabeForm
                key={input.id}
                disabled={input.disabled}
                error={formik.errors[input.id]}
                id={input.id}
                label={input.label}
                onChange={handleInputChange}
                type={input.type}
                value={formik.values[input.id]}
              />
            ))}
          </Stack>
        </form>
      </StepWrapper>

      <SubmitError
        autoHide={false}
        linkComponent={isSTP ? renderStpLink() : null}
        msg={errorMessage}
        onClose={handleCloseSnackbar}
        open={openSnackbar}
      />
      <SlideUpModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        title={DATA.modal.title}
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: DATA.modal.content }}
          variant="body2"
        />
      </SlideUpModal>
    </Layout>
  )
}

export default ClabeFormPage
