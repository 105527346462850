import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Paper from 'components/Paper'
import FormText from 'components/Form/Text'
import { StackBusinessAddress } from 'components/Renders'
import { Arrow } from 'components/Icons'
import DATA from 'constants/pages/assisted.json'
import ADDRESS_DATA from 'constants/pages/businessAddress.json'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useColonies from 'hooks/useColonies'
import Select from 'components/Form/Select'
import { ROUTE_ASSISTED } from 'constants/routes'
import segment from 'helpers/segment'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const AssistedEditBusinessInfo = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [searchColony, setSearchColony] = useState(null)
  let data = ''

  const street = ADDRESS_DATA.FORMIKS_ID.STREET
  const outdoor = ADDRESS_DATA.FORMIKS_ID.OUTDOOR
  const interior = ADDRESS_DATA.FORMIKS_ID.INTERIOR
  const zip = ADDRESS_DATA.FORMIKS_ID.ZIP
  const municipality = ADDRESS_DATA.FORMIKS_ID.MUNICIPALITY
  const state = ADDRESS_DATA.FORMIKS_ID.STATE
  const colony = ADDRESS_DATA.FORMIKS_ID.COLONY
  const businessName = DATA.FORMIK_ID.BUSINESS_NAME

  const { result: colonies, error } = useColonies(searchColony) || {
    colonies: [],
  }
  const [errorZip, setErrorZip] = useState(false)

  const checkValidZip = (val) => val?.length === 5 && !errorZip

  const BUSINESS_ADRESS_VALIDATION = yup.object().shape({
    [businessName]: yup
      .string()
      .trim()
      .required(DATA.REQUIRED)
      .matches(/^.{6,44}$/, DATA.NAME_LENGTH)
      .matches(/^[A-Za-zÀ-ÿ0-9.\s\u00f1\u00d1\u0027]+$/, DATA.INVALID_ENTRY),
    [colony]: yup
      .string()
      .required(ADDRESS_DATA.errorSelect)
      .matches(/^[A-Za-zÀ-ÿ.\s\u00f1\u00d1\u0027]+$/, DATA.INVALID_ENTRY),
    [outdoor]: yup
      .string()
      .required(ADDRESS_DATA.required)
      .matches(/^[0-9.\s\u00f1\u00d1\u0027]+$/, DATA.INVALID_ENTRY),
    [street]: yup
      .string()
      .required(ADDRESS_DATA.required)
      .matches(/^[A-Za-zÀ-ÿ.\s\u00f1\u00d1\u0027]+$/, DATA.INVALID_ENTRY),
    [zip]: yup
      .string()
      .required(ADDRESS_DATA.errorZip)
      .test('test-validity', ADDRESS_DATA.errorZip, (value) =>
        checkValidZip(value)
      ),
  })

  const formik = useFormik({
    initialValues: {
      [businessName]: data?.name || '',
      [colony]: data?.colony || '',
      [municipality]: data?.municipality || '',
      [outdoor]: data?.external_number || '',
      [state]: data?.state || '',
      [street]: data?.street || '',
      [zip]: data?.postal_code || '',
    },
    onSubmit: (values) => {
      const payload = {
        address: {
          colony: values[colony],
          external_number: values[outdoor].toString(), // number_out
          internal_number: values[interior] || '', // external_number
          municipality: values[municipality],
          postal_code: values[zip].toString(),
          state: values[state],
          street: values[street],
        },
        industry_id: JSON.parse(
          sessionStorage.getItem(DATA.SESSION_BUSINESS_DATA)
        ).industry_id,
        name: values[businessName].replace(/\s+/g, ' ').trim(),
      }

      sessionStorage.setItem(
        DATA.SESSION_BUSINESS_DATA,
        JSON.stringify(payload)
      )

      segment.track(DATA.SEGMENT.EVENTS.EDIT_BUSINESS_INFO)
      navigate(ROUTE_ASSISTED)
    },
    validationSchema: BUSINESS_ADRESS_VALIDATION,
  })

  const groupSetFieldValues = () => {
    formik.setFieldValue(municipality, data?.address?.municipality)
    formik.setFieldValue(state, data?.address?.state)
    formik.setFieldValue(zip, data?.address?.postal_code)
    formik.setFieldValue(colony, data?.address?.colony)
    formik.setFieldValue(outdoor, data?.address?.external_number)
    formik.setFieldValue(street, data?.address?.street)
    formik.setFieldValue(businessName, data?.name)
  }

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    data = JSON.parse(sessionStorage.getItem(DATA.SESSION_BUSINESS_DATA))

    if (data) {
      groupSetFieldValues(data)
    }
  }, [])

  useEffect(() => {
    if (data?.address?.postal_code) {
      setSearchColony(data?.address?.postal_code)
    }
    if (colonies?.length === 0) {
      setErrorZip(true)
    }
    if (colonies?.length === 1) {
      formik.setFieldValue(colony, colonies[0].label)
    }
    if (colonies?.length > 0) {
      formik.setFieldValue(municipality, colonies[0].municipality)
      formik.setFieldValue(state, colonies[0].state)
    }

    setErrorZip(error)
  }, [colonies, error])

  const handleChangeZIP = (e) => {
    const { value } = e.target

    // setSearchColony(null)
    setErrorZip(false)

    formik.handleChange(e)
    formik.setFieldValue(colony, '')
    formik.setFieldValue(municipality, '')
    formik.setFieldValue(state, '')
    formik.setFieldTouched(zip, false, false)
    formik.setFieldTouched(colony, false, false)

    if (value.length === 5) {
      setSearchColony(value)
    }
  }

  const handleKeyPress = (e) => {
    const { value } = e.target

    if (value.length === 5) {
      e.preventDefault()
    }
  }

  const handleChange = (e) => {
    formik.setFieldValue(colony, e.value)
  }

  return (
    <Layout>
      <Stack sx={{ mb: 1, p: 0, width: '100%' }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{ justifyContent: 'flex-start' }}
        >
          <Arrow size={1} />
        </Button>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
          width: '100%',
        }}
      >
        <Box>
          <Stack spacing={1.5} sx={{ mb: 3 }}>
            <Typography fontWeight={theme.fontWeight.bold} variant="body5">
              {DATA.TITLE.BUSINESS}
            </Typography>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              {DATA.BUSINESS_DATA.DESCRIPTION}
            </Typography>
          </Stack>

          <Typography
            fontWeight={theme.fontWeight.bold}
            sx={{ mb: 2 }}
            variant="body2"
          >
            {DATA.BUSINESS_DATA.BUSINESS_NAME}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Paper sx={{ mb: 3, p: 0 }}>
              <StackBusinessAddress
                sx={{
                  '& .MuiFormControl-root': {
                    m: 'auto',
                    width: 'calc(100% - 16px)',
                  },
                  '& .MuiInput-root': {
                    pl: 0,
                  },
                  '& .MuiInputBase-root.MuiInput-root:not(.Mui-error):before, & .MuiInputBase-root.MuiInput-root:not(.Mui-error):after':
                    {
                      border: 'none',
                    },
                }}
              >
                <FormText
                  error={
                    formik.touched[businessName] && formik.errors[businessName]
                  }
                  errorHelperIcon
                  id={businessName}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.PERSONAL_DATA.NAMES}
                  onChange={formik.handleChange}
                  value={formik.values[businessName]}
                  variant="standard"
                />
              </StackBusinessAddress>
            </Paper>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 3 }}
            >
              <Typography
                fontWeight={theme.fontWeight.bold}
                sx={{ mb: 2 }}
                variant="body2"
              >
                {DATA.BUSINESS_DATA.BUSINESS_ADDRESS}
              </Typography>
            </Stack>
            <Paper sx={{ mb: 3, p: 0 }}>
              <StackBusinessAddress
                sx={{
                  '& .MuiButton-root.filled ': {
                    mt: 1,
                    pl: 0,
                  },
                  '& .MuiButtonBase-root': {
                    margin: 'auto',
                    paddingLeft: 0,
                    width: 'calc(100% - 16px)',
                  },
                  '& .MuiFormControl-root': {
                    m: 'auto',
                    width: 'calc(100% - 16px)',
                  },
                  '& .MuiInput-root': {
                    pl: 0,
                  },
                }}
              >
                <FormText
                  error={formik.touched[street] && formik.errors.street}
                  errorHelperIcon
                  id={street}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={ADDRESS_DATA.form.street}
                  onChange={formik.handleChange}
                  value={formik.values.street}
                  variant="standard"
                />
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ m: 'auto', width: 'calc(100% - 16px)' }}
                >
                  <FormText
                    error={formik.touched[outdoor] && formik.errors.outdoor}
                    errorHelperIcon
                    id={outdoor}
                    InputProps={{
                      endAdornment: <>&nbsp;</>,
                    }}
                    label={ADDRESS_DATA.form.outdoor_number}
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.outdoor}
                    variant="standard"
                  />
                  <FormText
                    helperText={ADDRESS_DATA.optional}
                    id={interior}
                    InputProps={{
                      endAdornment: <>&nbsp;</>,
                    }}
                    label={ADDRESS_DATA.form.interior_number}
                    onChange={formik.handleChange}
                    value={formik.values.interior}
                    variant="standard"
                  />
                </Stack>
                <FormText
                  error={(formik.touched[zip] && formik.errors.zip) || errorZip}
                  errorHelperIcon
                  id={zip}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={ADDRESS_DATA.form.zip}
                  onChange={(e) => handleChangeZIP(e)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  value={formik.values.zip}
                  variant="standard"
                />
                {searchColony && colonies?.length ? (
                  <Select
                    error={formik.touched[colony] && formik.errors.colony}
                    errorHelperIcon
                    items={colonies}
                    label={ADDRESS_DATA.form.colony}
                    onChange={handleChange}
                    sx={{
                      '& label.MuiInputLabel-root': {
                        left: '-14px',
                        pl: 0,
                        top: '-16px',
                        zIndex: 'auto',
                      },
                      '& label.MuiInputLabel-root .MuiTypography-root': {
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        fontWeight: theme.fontWeight.semibold,
                      },
                      border: 'none',
                      borderBottom:
                        formik.touched[colony] && formik.errors.colony
                          ? `1px solid ${theme.palette.error.main}`
                          : `1px solid ${theme.backgrounds[2]}`,
                      borderRadius: 'initial',
                    }}
                    text={formik.values.colony || undefined}
                    value={formik.values[colony]}
                  />
                ) : (
                  <FormText
                    disabled
                    error={formik.touched[colony] && formik.errors.colony}
                    errorHelperIcon
                    id="colony"
                    InputProps={{
                      endAdornment: <>&nbsp;</>,
                    }}
                    label={ADDRESS_DATA.form.colony}
                    variant="standard"
                  />
                )}
                <FormText
                  disabled
                  errorHelperIcon
                  id={municipality}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={ADDRESS_DATA.form.municipality}
                  onChange={formik.handleChange}
                  value={formik.values.municipality}
                  variant="standard"
                />
                <FormText
                  className="BorderNone"
                  disabled
                  errorHelperIcon
                  id={state}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={ADDRESS_DATA.form.state}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  variant="standard"
                />
              </StackBusinessAddress>
            </Paper>
          </form>
        </Box>

        <Button
          color="orange"
          onClick={formik.handleSubmit}
          size="full-width"
          sx={{
            height: '48px',
          }}
          variant="primary-form"
        >
          {DATA.SAVE}
        </Button>
      </Box>
    </Layout>
  )
}

export default AssistedEditBusinessInfo
