import { useEffect, useState } from 'react'
import { fetchInfo } from 'helpers/fetchUtils'
import { isWebView } from 'utils'
import { saveToken } from 'helpers/tokenHelpers'

import {
  ENV,
  ROUTE_API_STATUS,
  ROUTE_PROFILE_SETUP,
  ROUTE_ACCOUNT_CREATION,
} from 'constants/routes'

const useCheckStep = (path) => {
  const [isValidStep, setResponseData] = useState(null)
  const [loading, setLoading] = useState(true)

  const baseURL = `${ENV.REACT_APP_API_URL}${ROUTE_API_STATUS}`

  const clearSteps = () => {
    localStorage.removeItem('pending')
  }

  const setSteps = (value) => {
    clearSteps()
    localStorage.setItem('pending', value)
  }

  const getExpectedStep = () => {
    let nextSteps = localStorage.getItem('pending')
    let result

    if (nextSteps) {
      nextSteps = nextSteps.split(',')

      const next = nextSteps.shift()

      localStorage.setItem('pending', nextSteps)
      if (!nextSteps[0]) {
        clearSteps()
      }

      result = next
    }

    return result
  }

  const isRightStep = async () => {
    let result = true

    if (!isWebView()) {
      /* eslint-disable camelcase */
      const {
        data: {
          navigation_step: { completed_steps, exceptions, pending_step },
        },
      } = await fetchInfo(baseURL).catch(() => {
        saveToken('')
        window.location.href = ROUTE_ACCOUNT_CREATION
        setLoading(false)
      })

      let expectedsStoredStep = getExpectedStep()

      if (path === ROUTE_PROFILE_SETUP) {
        clearSteps()
        expectedsStoredStep = undefined
      } else if (!expectedsStoredStep) {
        setSteps(pending_step)
        expectedsStoredStep = getExpectedStep()
      }

      if (
        !(
          path === expectedsStoredStep ||
          completed_steps?.includes(path) ||
          exceptions?.includes(path)
        )
      ) {
        getExpectedStep()
        result = false
      }

      setLoading(false)
    }
    setLoading(false)
    setResponseData(result)
  }

  useEffect(() => {
    isRightStep()
  }, [])

  return {
    isValidStep,
    loading,
  }
}

export default useCheckStep
