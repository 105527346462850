import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Layout from 'components/Layout'
import DIALOGS from 'constants/dialogs.json'
import SYNC from 'constants/pages/sync.json'
import { fetchInfo } from 'helpers/fetchUtils'
import isKycAccepted from 'helpers/kycHelper'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { debugPrint } from 'utils'
import {
  ENV,
  ROUTE_API_STATUS,
  ROUTE_FINANCIAL_INFO,
  ROUTE_KYC_ERROR,
} from 'constants/routes'
import DATAIDENTIFY from 'constants/pages/verifyIdentity.json'
import SubmitError from 'components/SubmitError'
import { useUserFormContext, actions } from 'components/Form/store/user'
import LoadingDialog from '../components/LoadingDialog'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const SyncPage = () => {
  const [loading, setLoading] = useState(true)
  const { device } = useGlobalContext()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const source = `${ENV.REACT_APP_API_URL}${ROUTE_API_STATUS}`
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const uid = params.get('interviewId')
  const isDebug = params.get('debug')
  let attempts = 1
  const { userDispatch } = useUserFormContext()

  const kycIsCompleted = (onboardingData) =>
    onboardingData.userData.status === SYNC.STATE.completed ||
    isKycAccepted(onboardingData)

  const handleNavigate = (url) => {
    setLoading(false)
    debugPrint(isDebug, 'handleNavigate', url)

    navigate(url)
  }

  useEffect(() => {
    fetchInfo(source)
      .then(({ data }) => {
        debugPrint(isDebug, '{ data }', data)
        if (kycIsCompleted(data)) {
          userDispatch({
            kycAccepted: isKycAccepted(data),
            type: actions.UPDATE_KYC_ACCEPTED,
          })

          handleNavigate(ROUTE_FINANCIAL_INFO)
        } else if (data.userData.subSteps[0].interviewId === uid) {
          handleNavigate(ROUTE_KYC_ERROR)
        }
      })
      .catch(() => {
        setOpenSnackbar(true)
        setLoading(false)

        cleverTap.event(DATAIDENTIFY.CLEVERTAP.EVENTS, {
          ActionType: DATAIDENTIFY.CLEVERTAP.ACTION_TYPE.ERROR,
          ErrorMessage: DATAIDENTIFY.CLEVERTAP.TARGET.error,
          OS: device.type,
        })
      })
  }, [])

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    const timer = setInterval(() => {
      if (uid && attempts < SYNC.ATTEMPT_COUNT && loading) {
        debugPrint(isDebug, `[Attempt: ${attempts}]`, null)

        fetchInfo(source).then(({ data }) => {
          if (data.userData.subSteps[0].interviewId === uid) {
            debugPrint(isDebug, '------FINISH---------', null)

            if (kycIsCompleted(data)) {
              userDispatch({
                kycAccepted: isKycAccepted(data),
                type: actions.UPDATE_KYC_ACCEPTED,
              })

              handleNavigate(ROUTE_FINANCIAL_INFO)

              cleverTap.event(DATAIDENTIFY.CLEVERTAP.EVENTS, {
                ActionType: DATAIDENTIFY.CLEVERTAP.ACTION_TYPE.CLICK,
                OS: device.type,
                Page: DATAIDENTIFY.CLEVERTAP.PAGE[1],
              })
            } else {
              handleNavigate(ROUTE_KYC_ERROR)
            }
          }
        })
        attempts += 1
      } else {
        debugPrint(isDebug, 'FINISH TIMER!!!!', null)
        if (attempts === SYNC.ATTEMPT_COUNT) {
          handleNavigate(ROUTE_KYC_ERROR)
        }
        clearInterval(timer)
      }
    }, SYNC.TIMER.interval)

    return () => clearInterval(timer)
  }, [loading])

  const handleToggleSnackbar = (toggle) => setOpenSnackbar(!toggle)

  return (
    <Layout>
      <LoadingDialog
        // callback={nextPage}
        content={DIALOGS.sync}
        done={!loading}
        loading={loading}
      />
      <Typography
        color="white.main"
        data-testid="search-query-id-parameter"
        variant="body1"
      >
        {uid}
      </Typography>
      <SubmitError
        msg={SYNC.ERROR.message}
        onClose={handleToggleSnackbar}
        open={openSnackbar}
      />
    </Layout>
  )
}

export default SyncPage
