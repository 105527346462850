import { useState } from 'react'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Layout from 'components/Layout'
import { actions, useUserFormContext } from 'components/Form/store/user'
import StepWrapper from 'components/StepWrapper'
import FormPassword from 'components/Form/Password'
import FormText from 'components/Form/Text'
import {
  ENV,
  ROUTE_POST_LOGIN,
  ROUTE_PROFILE_SETUP,
  ROUTE_API_STATUS,
} from 'constants/routes'
import { postInfo, fetchInfo } from 'helpers/fetchUtils'
import { saveToken } from 'helpers/tokenHelpers'
import DATA from 'constants/pages/loginForm.json'
import DIALOGS from 'constants/dialogs.json'
import LoadingDialog from 'components/LoadingDialog'
import * as yup from 'yup'
import { tokenBridge } from 'helpers/callNativeBridge'
import segment from 'helpers/segment'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { getEmailDebug, validateEmail } from 'utils'

const LoginPage = () => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { userDispatch, userState } = useUserFormContext()
  const navigate = useNavigate()
  const inputPwd = DATA.FORMIKS_ID.PASSWORD
  const inputEmail = DATA.FORMIKS_ID.EMAIL
  const ldClient = useLDClient()
  const baseURL = `${ENV.REACT_APP_API_URL}${ROUTE_API_STATUS}`

  const INPUTS_VALIDATION = yup.object().shape({
    [inputEmail]: yup
      .string()
      .test('test-not-empty', DATA.errorLabelEmailEmpty, (value) => !!value)
      .test(
        'test-email-valid',
        DATA.errorLabelEmail,
        (value) => value && validateEmail(getEmailDebug(value))
      ),
    [inputPwd]: yup
      .string()
      .test('test-non-empty', DATA.errorLabel, (value) => !!value),
  })

  const formik = useFormik({
    initialValues: {
      [inputEmail]: userState.email || '',
      [inputPwd]:
        userState.email && userState.password ? userState.password : '',
    },
    onSubmit: (values) => {
      setLoading(true)
      userDispatch({
        password: values[inputPwd],
        type: actions.UPDATE_PASSWORD,
      })

      postInfo(`${ENV.REACT_APP_API_URL_2}${ROUTE_POST_LOGIN}`, {
        ...DATA.postData,
        email: values[inputEmail],
        password: values[inputPwd],
      })
        .then(async ({ data }) => {
          await saveToken(data.access_token)
          tokenBridge()

          fetchInfo(baseURL)
            .then((status) => {
              const { mid } = status.data

              userDispatch({
                mid,
                type: actions.UPDATE_MID,
              })

              segment.identify(mid)

              ldClient.identify({ key: mid })
            })
            .catch((err) => {
              console.error('Error fetching status', err)
            })
          segment.track(DATA.SEGMENT.EVENTS.LOGIN_SUCCESS)
          setSuccess(true)
        })
        .catch(() => {
          setError(DATA.errorLogin)
        })
        .finally(() => setLoading(false))
    },
    validationSchema: INPUTS_VALIDATION,
  })

  const handleChange = (event) => {
    formik.handleChange(event)
    setError(null)
  }

  const nextPage = () => {
    navigate(ROUTE_PROFILE_SETUP)
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper content={DATA.content} onContinue={formik.handleSubmit}>
        <>
          <LoadingDialog
            callback={nextPage}
            content={DIALOGS.validating}
            done={success}
            loading={loading}
          />
          <Stack alignItems="space-between">
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <FormText
                  disabled={userState.email}
                  error={
                    (formik.touched[inputEmail] && formik.errors[inputEmail]) ||
                    error
                  }
                  id={inputEmail}
                  label={DATA.PLACEHOLDER.MAIL}
                  onChange={handleChange}
                  placeholder={DATA.PLACEHOLDER.MAIL}
                  value={formik.values[inputEmail]}
                />
                <FormPassword
                  error={
                    (formik.touched[inputPwd] && formik.errors[inputPwd]) ||
                    error
                  }
                  id={inputPwd}
                  onChange={handleChange}
                  placeholder={DATA.PLACEHOLDER.PASSWORD}
                  value={formik.values[inputPwd]}
                />
              </Stack>
            </form>
          </Stack>
        </>
      </StepWrapper>
    </Layout>
  )
}

export default LoginPage
