import { useTheme } from '@mui/styles'
import Paper from 'components/Paper'
import Layout from 'components/Layout'
import Button from 'components/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { ROUTE_VERIFY, ROUTE_OPTIONAL_KYC } from 'constants/routes'
import DATA from 'constants/pages/kycError.json'
import DATAINDENTIFY from 'constants/pages/verifyIdentity.json'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorIcon, Thick } from 'components/Icons'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const KYCError = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const navigate = useNavigate()

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    cleverTap.event(DATAINDENTIFY.CLEVERTAP.EVENTS, {
      ActionType: DATAINDENTIFY.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATAINDENTIFY.CLEVERTAP.PAGE[2],
    })
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const handleReset = () => {
    cleverTap.event(DATAINDENTIFY.CLEVERTAP.EVENTS, {
      ActionType: DATAINDENTIFY.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATAINDENTIFY.CLEVERTAP.TARGET.retry,
    })
    navigate(ROUTE_VERIFY)
  }

  const handleRedirect = () => {
    navigate(ROUTE_OPTIONAL_KYC)
  }

  return (
    <Layout>
      <Stack
        spacing={2}
        sx={{
          flex: 1,
          justifyContent: 'space-evenly',
          p: 2,
          textAlign: 'center',
          width: '95%',
        }}
      >
        <Stack spacing={1} sx={{ '&.MuiStack-root': { margin: 'auto' } }}>
          <Stack
            sx={{
              '&.MuiStack-root': {
                margin: 'auto',
              },
              backgroundColor: theme.backgrounds[9],
              borderRadius: theme.borderRadius[0],
              height: 120,
              margin: 'auto',
              p: 4,
              width: 120,
            }}
          >
            <ErrorIcon size={4} />
          </Stack>
          <Typography fontWeight={theme.fontWeight.bold} variant="body5">
            {DATA.title}
          </Typography>
          <Typography color="text.primary" variant="body2">
            {DATA.subtitle}
          </Typography>
          <Paper sx={{ margin: 'auto', p: 3 }}>
            <Stack>
              {DATA.items.map((it) => (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center' }}
                >
                  <Thick color={theme.palette.success.checkmark} size={0.8} />
                  <Typography align="left" color="text.primary" variant="body2">
                    {it}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Paper>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        sx={{ display: 'flex', justifyContent: 'flex-end', width: '95%' }}
      >
        <Button color="orange" onClick={handleReset} size="large" type="link">
          {DATA.resetButton}
        </Button>
        <Button onClick={handleRedirect} size="large" type="link">
          {DATA.contact}
        </Button>
      </Stack>
    </Layout>
  )
}

export default KYCError
