import React, { useState } from 'react'
import { getOSName } from 'utils'
import Cookies from 'js-cookie'
import { useUserFormContext } from 'components/Form/store/user'

const DebugConsole = () => {
  const [isVisible, setIsVisible] = useState(false)

  const { userState } = useUserFormContext()
  const operativeSystem = getOSName()
  const appVersion = Cookies.get('app_version')

  if (
    process.env.REACT_APP_ENV !== 'dev' &&
    process.env.REACT_APP_ENV !== 'stage'
  ) {
    return null
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <button
        onClick={toggleVisibility}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: 'transparent',
          position: 'fixed',
          right: 0,
          zIndex: 9999,
        }}
        type="button"
      >
        Toggle Console
      </button>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          bottom: 0,
          color: 'white',
          display: isVisible ? 'block' : 'none',
          height: '90vh',
          left: 0,
          margin: 'auto',
          overflow: 'auto',
          padding: '1rem',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 9999,
        }}
      >
        <pre>
          {JSON.stringify(
            {
              appVersion,
              operativeSystem,
              userState,
            },
            null,
            2
          )}
        </pre>
      </div>
    </>
  )
}

export default DebugConsole
