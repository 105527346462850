import { useCallback, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import {
  Alert,
  Box,
  Button,
  Snackbar,
  useTheme,
  CircularProgress,
} from '@mui/material'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import {
  ENV,
  ROUTE_API_SIGNUP,
  ROUTE_API_SIGNUP_VALIDATE,
  ROUTE_ASSISTED,
  ROUTE_ACCOUNT_CREATION,
  ROUTE_PHONE,
  ROUTES_API_ASSISTED_STATUS,
} from 'constants/routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GenericDialog from 'components/GenericDialog'
import { actions, useUserFormContext } from 'components/Form/store/user'
import { fetchInfo, postInfo } from 'helpers/fetchUtils'
import { saveToken } from 'helpers/tokenHelpers'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'

import OTPForm from 'components/Form/OTP'
import CountDown from 'components/CountDown'

import DIALOGS from 'constants/dialogs.json'
import DATA from 'constants/pages/validationForm.json'
import SITE from 'constants/site.json'
import { CheckMark } from 'components/Icons'
import { tokenBridge } from 'helpers/callNativeBridge'
import segment from 'helpers/segment'
import { getAppsFlyerUrl, removeAppsFlyerUrl } from 'helpers/appsflyer'
import { getQueryParamsStringFromSessionStorage } from 'helpers/params'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { getOSName } from 'utils'

const ValidationFormPage = () => {
  const theme = useTheme()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { device } = useGlobalContext()
  const { userState: formState, userDispatch } = useUserFormContext()
  const [otp, setOtp] = useState()
  const ldClient = useLDClient()
  const OBAssistedEmail =
    params.get('assisted_email') || sessionStorage.getItem('OBAssistedEmail')

  const OBAssistedOppId =
    params.get('opportunity') || sessionStorage.getItem('OBAssistedOppId')

  const [openModal, setOpenModal] = useState(false)

  // LoadingDialog variables
  const [saving, setSaving] = useState(false)

  // GenericDialog experied OTP
  const [expiredCode, setExpiredCode] = useState(false)

  // OTP inputs variants
  const [filled, setFilled] = useState(false)
  const [OTPpassed, setOTPpassed] = useState(null)
  // retry flags / GenericDialog for max tries

  const [resendEnabled, setResendEnabled] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [redirectToAssisted, setRedirectToAssisted] = useState(false)

  const encodedMail = formState?.email?.replace(/\+/g, '%2B')
  const assistedStatus = `${ENV.REACT_APP_API_URL}${ROUTES_API_ASSISTED_STATUS}?email=${encodedMail}`

  useEffect(() => {
    if (!formState.email || !formState.password) {
      navigate(ROUTE_ACCOUNT_CREATION)
    } else {
      fetchInfo(assistedStatus)
        .then(({ data }) => {
          setRedirectToAssisted(data.is_assisted)
        })
        .catch(() => {
          setRedirectToAssisted(false)
        })
    }
  }, [formState])

  useEffect(() => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const focus1stOtp = () =>
    setTimeout(() => {
      document.querySelector('#otp-input input')?.focus()
    })

  const checkCode = async () => {
    const { email } = formState

    const payload = !OBAssistedOppId
      ? {
          ...DATA.signup,
          email,
          has_accepted_clip: true,
          validation_id: otp,
        }
      : {
          ...DATA.signup,
          email,
          has_accepted_clip: true,
          opportunity: OBAssistedOppId,
          validation_id: otp,
        }

    const appsFlyerUrl = getAppsFlyerUrl()
    const queryParamsString = getQueryParamsStringFromSessionStorage()

    if (appsFlyerUrl || queryParamsString) {
      payload.source = []
    }

    if (appsFlyerUrl) {
      payload.source.push({
        type: 'appsFlyer',
        value: appsFlyerUrl,
      })
    }

    if (queryParamsString) {
      payload.source.push({
        type: 'webOnboarding',
        value: queryParamsString,
      })
    }

    setSaving(true)
    postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_SIGNUP_VALIDATE}`, payload)
      .then(async ({ data }) => {
        userDispatch({
          mid: data.mid,
          type: actions.UPDATE_MID,
        })
        localStorage.setItem(SITE.LS_MID, data.mid) // save for future use when leaving the site (KYC, page refresh, etc)
        await saveToken(data.access_token)

        ldClient.identify({
          custom: {
            kind: 'user',
            signupTimestamp: new Date().toISOString(),
            webviewOS: getOSName(),
          },
          email,
          key: data.mid,
        })

        segment.identify(data.mid, {
          email,
        })
        segment.track(DATA.SEGMENT.EVENTS.SIGNUP_SUCCESS)

        if (data.statusCode === 200) {
          removeAppsFlyerUrl()
        }

        tokenBridge()
        setOTPpassed(true)

        if (OBAssistedEmail || redirectToAssisted) {
          sessionStorage.removeItem('OBAssistedEmail')
          sessionStorage.removeItem('OBAssistedOppId')
          navigate(ROUTE_ASSISTED)
        } else {
          navigate(ROUTE_PHONE)
        }
      })
      .catch((axiosError) => {
        const { response } = axiosError

        if (response && [605, 604].includes(response.data.appErrorCode)) {
          setOpenModal(true)
        } else if (response && response.data.appErrorCode === 603) {
          setExpiredCode(true)
          cleverTap.event(DATA.CLEVERTAP.EVENTS, {
            ActionType: DATA.CLEVERTAP.ERROR,
            OS: device.type,
            Target: DATA.CLEVERTAP.TARGET.expired,
          })

          segment.track(DATA.SEGMENT.EVENTS.EXPIRED_CODE)
        } else {
          cleverTap.event(DATA.CLEVERTAP.EVENTS, {
            ActionType: DATA.CLEVERTAP.ERROR,
            OS: device.type,
            Target: DATA.CLEVERTAP.TARGET.invalid,
          })

          segment.track(DATA.SEGMENT.EVENTS.INVALID_CODE)

          setOtp()
          setFilled(false)
          focus1stOtp()
        }
        setOTPpassed(false)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const handleChange = (val) => {
    setOtp(val)
    setOTPpassed(true)

    switch (val.length) {
      case 0:
        setOTPpassed()
        break
      case 6:
        setFilled(true)
        break
      default:
        setFilled(false)
        break
    }
  }

  const handleTimer = useCallback(() => {
    setResendEnabled(true)
  }, [])

  const handleResend = () => {
    setResendEnabled(false)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.resend,
    })

    segment.track(DATA.SEGMENT.EVENTS.RESEND_CODE)

    postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_SIGNUP}`, {
      ...DATA.signup,
      email: formState.email,
      password: formState.password,
    }).then((response) => setOpenSnackbar(response?.status === 200))
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const renderText = () => {
    if (saving) return null

    return resendEnabled ? (
      <Box textAlign="center">
        <Button onClick={handleResend} type="link">
          {DATA.resendTxt}
        </Button>
      </Box>
    ) : (
      <p>
        {DATA.resendCountdown}
        <strong>
          <CountDown
            format={1}
            onComplete={handleTimer}
            timeout={DATA.resendTimeout}
          />
        </strong>
      </p>
    )
  }

  useEffect(() => {
    if (filled) {
      checkCode()
    }
  }, [filled])

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        controls={DATA.controls}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        // onContinue={() => {
        //   if (filled && OTPpassed !== false) {
        //     checkCode()
        //   }
        // }}
      >
        <>
          {/* <LoadingDialog
            callback={nextPage}
            content={DIALOGS.validating}
            done={success}
            loading={saving}
          /> */}
          <GenericDialog
            callback={() => {
              handleResend()
              setOTPpassed(null)
              setOtp()
              setOpenModal(false)
              focus1stOtp()
            }}
            content={DIALOGS.retryExceeded}
            onClose={() => navigate(ROUTE_ACCOUNT_CREATION)}
            open={openModal}
            outsideClick={() => setOpenModal(false)}
          >
            <Typography sx={{ mt: 1 }} variant="body1">
              {DIALOGS.retryExceeded.title}
            </Typography>
            <Typography
              align="center"
              fontWeight={theme.fontWeight.regular}
              sx={{ padding: `${theme.space[3]}px ${theme.space[5]}px` }}
              variant="body1"
            >
              {DIALOGS.retryExceeded.description}
            </Typography>
          </GenericDialog>
          {/* EXPIRED CODE */}
          <GenericDialog
            callback={() => {
              handleResend()
              setOTPpassed(null)
              setOtp()
              setExpiredCode(false)
              focus1stOtp()
            }}
            content={DIALOGS.expired}
            open={expiredCode}
          >
            <Typography sx={{ mt: 1 }} variant="body1">
              {DIALOGS.expired.title}
            </Typography>
            <Typography
              align="center"
              fontWeight={theme.fontWeight.regular}
              sx={{ padding: `${theme.space[3]}px ${theme.space[5]}px` }}
              variant="body1"
            >
              {DIALOGS.expired.description}
            </Typography>
          </GenericDialog>
          <Typography variant="body2">
            {DATA.subtitle} <strong>{formState.email}</strong>
          </Typography>
          <OTPForm
            error={OTPpassed === false}
            id="otp-input"
            onChange={handleChange}
            value={otp}
          />
          <Typography
            sx={{
              fontWeight: theme.fontWeight.regular,
              pt: `${theme.space[6]}px`,
            }}
            variant="body1"
          >
            {saving && (
              <Box alignItems="center" display="flex" justifyContent="center">
                <CircularProgress
                  size={16}
                  sx={{
                    color: theme.palette.primary.main,
                    mr: 1,
                  }}
                />
                <Typography variant="body0">{DATA.verifyingCode}</Typography>
              </Box>
            )}
            {renderText()}
          </Typography>
          <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            autoHideDuration={DATA.toastTimeout}
            onClose={handleCloseSnackbar}
            open={openSnackbar}
            sx={{ mb: 10 }}
          >
            <Alert
              icon={<CheckMark />}
              onClose={handleCloseSnackbar}
              severity="success"
              slotProps={{
                closeButton: {
                  sx: {
                    display: 'none',
                  },
                },
              }}
              sx={{ width: '100%' }}
            >
              {DATA.retrySuccess}
            </Alert>
          </Snackbar>
        </>
      </StepWrapper>
    </Layout>
  )
}

export default ValidationFormPage
