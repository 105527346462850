/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Layout from 'components/Layout'
import ProfileStepper from 'components/ProfileStepper'
import useFetch from 'hooks/useFetch'
import {
  ENV,
  ROUTE_API_STATUS,
  ROUTE_ACCOUNT_CREATION,
  ROUTE_OPTIONAL_KYC,
  ROUTE_INSTALLMENTS,
  ROUTE_CLABE,
} from 'constants/routes'
import LoadingDialog from 'components/LoadingDialog'
import SETUP from 'constants/profileSetup.json'
import DIALOGS from 'constants/dialogs.json'
import { getToken, saveToken } from 'helpers/tokenHelpers'
import isKycAccepted from 'helpers/kycHelper'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { useUserFormContext, actions } from 'components/Form/store/user'
import { isWebView } from 'utils'
import Cookies from 'js-cookie'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const ProfileSetupPage = () => {
  const [stepList, setStepList] = useState([])
  const theme = useTheme()
  const navigate = useNavigate()
  const baseURL = `${ENV.REACT_APP_API_URL}${ROUTE_API_STATUS}`
  const { loading, responseData: status, error } = useFetch(baseURL)
  const { device } = useGlobalContext()
  const { userDispatch } = useUserFormContext()

  const appVersion = Cookies.get('app_version')

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    if (getToken()) {
      cleverTap.event(SETUP.CLEVERTAP.EVENTS, {
        ActionType: SETUP.CLEVERTAP.ACTION_TYPE.VIEW,
        OS: device.type,
        Page: SETUP.CLEVERTAP.PAGE,
      })
    }
  }, [])

  useEffect(() => {
    if (status) {
      userDispatch({
        kycAccepted: isKycAccepted(status),
        type: actions.UPDATE_KYC_ACCEPTED,
      })

      segment.identify(status.mid)
      segment.page(SETUP.SEGMENT.PAGE)
      segment.track(SETUP.SEGMENT.EVENTS.VIEW_RESUME_ACCOUNT_SET_UP)
    }

    if (status?.activate.status === SETUP.STATE.completed) {
      if (isKycAccepted(status)) {
        navigate(ROUTE_INSTALLMENTS)
      } else {
        navigate(ROUTE_CLABE)
      }
    }

    if (status) {
      const steps = isKycAccepted(status)
        ? SETUP.STEPS_1_2
        : SETUP.STEPS_1_2_manual

      let filterSteps
      const arraySteps = steps.map((step) => ({
        ...step,
        list: step.list.map((item) => ({
          ...item,
          status: status[step.id]?.subSteps?.find(
            (s) => s.stepId === item.stepId
          )?.status,
        })),
        status: status[step.id].status,
      }))

      if (!isWebView()) {
        const filteredUserData = arraySteps.filter(
          (step) => step.id === 'userData'
        )

        const userData = filteredUserData[0]?.list?.map((sub) => {
          const subStep = { ...sub }

          subStep.url = ROUTE_OPTIONAL_KYC

          return subStep
        })

        filterSteps = arraySteps.map((currentStep) => {
          const step = { ...currentStep }

          if (step.id === 'userData' && step.status === 'PENDING') {
            step.list = userData
          }

          return step
        })
      } else {
        filterSteps = arraySteps
      }

      if (appVersion) {
        segment.track(SETUP.SEGMENT.EVENTS.TRACK_VERSION, {
          app_version: appVersion,
        })
      }

      setStepList(filterSteps)
    }
  }, [status])

  useEffect(() => {
    if (!loading && error) {
      saveToken('')
      navigate(ROUTE_ACCOUNT_CREATION)
    }
  }, [loading])

  return (
    <Layout header={SETUP.HEADER}>
      <LoadingDialog content={DIALOGS.fetching} loading={loading} />
      <Stack sx={{ flexGrow: 1, padding: `${theme.space[5]}px` }}>
        <Typography
          color="black.main"
          sx={{ mb: `${theme.space[4]}px` }}
          variant="hxl"
        >
          {SETUP.TITLE}
        </Typography>

        <Typography
          color="black.main"
          sx={{ mb: `${theme.space[5]}px` }}
          variant="body2"
        >
          {SETUP.SUBTITLE}
        </Typography>
        {!loading && stepList?.length >= 0 && (
          <ProfileStepper items={stepList} />
        )}
      </Stack>
    </Layout>
  )
}

export default ProfileSetupPage
